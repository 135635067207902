import { FC } from 'react';
import { ActiveSession } from '@fjuel/schemas';
import { Box } from '@mui/material';
import { InlineStat } from '@fjuel/components/Global/InlineStat';
import { dateTimeShortFormatter, formatDurationShort } from '@fjuel/utils';
import { intervalToDuration, startOfMinute } from 'date-fns';

interface TopRightProps {
  session: ActiveSession;
}

export const TopRight: FC<TopRightProps> = ({ session }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <InlineStat label={'Start'} sx={{ whiteSpace: 'nowrap' }} value={dateTimeShortFormatter(session.timeStarted)} />
      <InlineStat
        label={'Duration'}
        sx={{ whiteSpace: 'nowrap' }}
        value={formatDurationShort(intervalToDuration({ start: session.timeStarted, end: startOfMinute(new Date()) }))}
      />
    </Box>
  );
};
