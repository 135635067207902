import { FC } from 'react';
import { CollapsibleMenu, MenuItem } from '@fjuel/components/SideMenu/CollapsibleMenu.tsx';
import { Boat, Buildings, Chalkboard, Lightning, Table } from '@phosphor-icons/react';
import { Plug } from '@fjuel/components/icons/Plug.tsx';
import { useApplicationContext } from '@fjuel/context/ApplicationContext';

interface ShorePowerConnectProps {
  onMenuClose?: () => void;
}

export const ShorePowerConnect: FC<ShorePowerConnectProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();

  const items: Array<MenuItem> = [
    { label: 'Dashboard', to: `/${siteId}`, icon: <Chalkboard weight="duotone" /> },
    { label: 'Sessions', to: `/${siteId}/session`, icon: <Table weight="duotone" /> },
    { label: 'Vessels', to: `/${siteId}/vessel`, icon: <Boat weight="duotone" />, disabled: !siteId },
    {
      label: 'Shore Power Units',
      to: `/${siteId}/shore-power-units`,
      icon: <Plug weight="duotone" />,
      disabled: !siteId,
    },
    { label: 'Companies', to: `/${siteId}/companies`, icon: <Buildings weight="duotone" />, disabled: !siteId },
  ];

  return (
    <>
      <CollapsibleMenu
        id={'shorePowerConnect'}
        title={'Shore Power Connect'}
        icon={<Lightning />}
        items={items}
        onMenuClose={onMenuClose}
      />
    </>
  );
};
