import { z } from 'zod';
import { PowerMetricsSchema } from '@fjuel/schemas/utils/PowerMetricsSchema';
import { EnergyUsageSchema } from '@fjuel/schemas/utils/EnergyUsage';

export const EnergyMonitoringSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  capacity: z.number(),
  usage: EnergyUsageSchema.array(),
  powerMetrics: PowerMetricsSchema.array(),
});

export type EnergyMonitoring = z.infer<typeof EnergyMonitoringSchema>;
