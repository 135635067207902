import { z } from 'zod';

import { IMOSchema } from '../../utils/IMOSchema';
import { ShorePowerUnitSchema } from '../shorePowerUnits/ShorePowerUnitSchema';
import { VesselSchema } from '../vessels/VesselSchema';

export const ActiveSessionSchema = z.object({
  id: z.string().uuid(),
  shorePowerUnitId: z.string().uuid(),
  shorePowerUnit: ShorePowerUnitSchema.nullable(),
  vesselImo: IMOSchema.nullable(),
  vesselImoChecked: z.boolean(),
  vesselId: z.string().uuid().nullable(),
  vessel: VesselSchema.nullable(),
  timeStarted: z.coerce.date(),
  activePower: z.number().nonnegative().nullish(),
  voltage: z.number().nonnegative(),
  frequency: z.number().nonnegative(),
  amperage: z.number(),
  powerPeak: z.number().nonnegative(),
  totalEnergyConsumed: z.number(),
  unitPrice: z.number().nonnegative().default(0),
  totalPrice: z.number().nonnegative().default(0),
  averagePower: z.number().nonnegative(),
});

export type ActiveSession = z.infer<typeof ActiveSessionSchema>;
