import { FC } from 'react';
import { Typography } from '@mui/material';
import { useDevice } from '@fjuel/context/Device/DeviceContext';

interface DisplayRangeProps {
  start: number;
  end: number;
  count: number;
}

export const DisplayRange: FC<DisplayRangeProps> = ({ start, end, count }) => {
  const { isPhone, isTablet } = useDevice();
  return (
    <Typography fontWeight={600}>
      {isPhone || isTablet ? `${count} results` : `Shows ${start} - ${end} of ${count} entries`}
    </Typography>
  );
};
