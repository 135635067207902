import { z } from 'zod';

export const EffectMarkupCommonSchema = z.object({
  upperLimit: z.coerce
    .number()
    .int('Upper limit must be an integer')
    .positive('Upper limit must be a positive integer')
    .describe('Upper limit of the effect in kW'),
  pricePerKwh: z.coerce.number().positive('Price must be positive').describe('Price per kWh'),
});

export function checkDuplicateUpperLimits(data: { upperLimit: number }[], ctx: z.RefinementCtx) {
  const upperLimits: Map<number, number[]> = new Map();

  data.forEach((item, index) => {
    const { upperLimit } = item;

    const existingIndices = upperLimits.get(upperLimit);
    if (existingIndices) {
      existingIndices.forEach((originalIndex) => {
        ctx.addIssue({
          message: `Duplicate upper limit: ${upperLimit}`,
          code: 'custom',
          path: [originalIndex, 'UpperLimit'],
        });
      });

      ctx.addIssue({
        message: `Duplicate upper limit: ${upperLimit}`,
        code: 'custom',
        path: [index, 'UpperLimit'],
      });

      existingIndices.push(index);
    } else {
      upperLimits.set(upperLimit, [index]);
    }
  });
}
