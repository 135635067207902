import { ShorePowerUnitActiveSession } from '@fjuel/pages/ShorePowerConnect/ShorePowerUnits/ActiveSessionTable.tsx';
import { ActiveSession, ActiveSessionSchema } from '@fjuel/schemas';
import { Box, Divider, Typography } from '@mui/material';
import { ComponentProps, FC } from 'react';

import { BottomLeft } from './BottomLeft.tsx';
import { BottomRight } from './BottomRight.tsx';
import { TopRight } from './TopRight.tsx';
import { TopLeft } from './TopLeft.tsx';
import { Header } from './Header.tsx';

export const ContainerPopover: FC<{
  unit: ShorePowerUnitActiveSession;
  onCloseClicked?: VoidFunction;
  onSeeDetailsClicked?: (unit: ShorePowerUnitActiveSession) => void;
}> = ({ unit, onCloseClicked, onSeeDetailsClicked }) => {
  // Mapbox serializes the session object to a string, so we need to parse it
  const session: ActiveSession | null =
    typeof unit.session === 'string' ? ActiveSessionSchema.parse(JSON.parse(unit.session)) : unit.session;

  return (
    <Box display={'flex'} gap={1} flexDirection={'column'} p={1}>
      <Header unit={unit} onCloseClicked={onCloseClicked} onSeeDetailsClicked={onSeeDetailsClicked} session={session} />
      <Typography>{unit.ipcName}</Typography>
      {session && (
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2}>
          <TopLeft session={session} />
          <TopRight session={session} />
          {(session.activePower ?? 0) > 0 && (
            <>
              <Divider sx={{ gridColumn: '1 / -1', borderColor: 'hsla(182, 44%, 33%, 1)' }} />
              <BottomLeft session={session} />
              <BottomRight session={session} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export type ContainerPopoverProps = ComponentProps<typeof ContainerPopover>;
