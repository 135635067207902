import { useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';

import { DeviceContext, DeviceContextType } from './DeviceContext';

interface DeviceProviderProps {
  children: ReactNode;
}

export const DeviceProvider: FC<DeviceProviderProps> = ({ children }) => {
  const theme = useTheme();

  // Define media queries
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo<DeviceContextType>(
    () => ({
      isPhone,
      isTablet,
      isDesktop,
    }),
    [isPhone, isTablet, isDesktop]
  );

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};
