import { z } from 'zod';

import { FileTypesSchema } from './FileTypesSchema';

export const SessionPriceLineTypes = { Consumption: 1, Addon: 2, Effect: 3 } as const;

const InvoiceLineSchema = z.object({
  invoiceId: z.string().uuid(),
  sessionId: z.string().uuid(),
  price: z.number().nullable(),
  consumption: z.number().nullable(),
  markup: z.number().nullable(),
  spotPrice: z.number().nullable(),
  from: z.coerce.date().nullable(),
  type: z.nativeEnum(SessionPriceLineTypes),
  quayName: z.string().nullable(),
  averagePriceKwh: z.number().nullable(),
  sessionDuration: z
    .object({
      days: z.number().int(),
      hours: z.number().int(),
      minutes: z.number().int(),
    })
    .nullable(),
  effectMarkup: z.number().nullable(),
  effectPeak: z.number().nullable(),
  effectPrice: z.number().nullable(),
});
export type InvoiceLine = z.infer<typeof InvoiceLineSchema>;

const VoucherDocumentSchema = z.object({
  voucherNo: z.number(),
  base64EncodedData: z.string(),
  fileName: z.string(),
  fileType: FileTypesSchema,
});
export type VoucherDocument = z.infer<typeof VoucherDocumentSchema>;

export const InvoiceDetailsSchema = z.object({
  invoiceLines: z.array(InvoiceLineSchema),
  invoiceFiles: z.array(VoucherDocumentSchema),
});
export type InvoiceDetails = z.infer<typeof InvoiceDetailsSchema>;
