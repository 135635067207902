import { fjuelApi } from '@fjuel/configs/api';
import { PriceMarkupCreate, PriceMarkupCreateSchema, PriceMarkupSchema } from '@fjuel/schemas/priceMarkup';
import { createMutation } from 'react-query-kit';

export const useCreatePriceMarkup = createMutation({
  mutationFn: async ({ priceMarkup }: { priceMarkup: PriceMarkupCreate }) => {
    const body = PriceMarkupCreateSchema.parse(priceMarkup);
    const response = await fjuelApi.post(`priceMarkup`, { json: body }).json();
    return PriceMarkupSchema.parse(response);
  },
});
