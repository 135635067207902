import { FC } from 'react';
import { ActiveSession } from '@fjuel/schemas';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { ShorePowerUnitActiveSession } from '@fjuel/pages/ShorePowerConnect/ShorePowerUnits/ActiveSessionTable.tsx';

import { ChargingIndicator } from './ChargingIndicator.tsx';

interface HeaderProps {
  unit: ShorePowerUnitActiveSession;
  onCloseClicked?: VoidFunction;
  onSeeDetailsClicked?: (unit: ShorePowerUnitActiveSession) => void;
  session: ActiveSession | null;
}

export const Header: FC<HeaderProps> = ({ unit, onCloseClicked, onSeeDetailsClicked, session }) => {
  const { palette } = useTheme();

  return (
    <Box display={'flex'} gap={1} whiteSpace={'nowrap'} alignItems={'center'}>
      <Typography whiteSpace={'normal'} fontWeight={600} fontSize={22}>
        {unit.name}
      </Typography>
      {session && <ChargingIndicator isCharging={true} />}
      {session && (
        <Button
          sx={{ textTransform: 'none', ml: 'auto', borderRadius: 2, px: 2.5, py: 0.5 }}
          variant="outlined"
          onClick={() => onSeeDetailsClicked?.({ ...unit, session })}
        >
          See details
        </Button>
      )}
      <IconButton onClick={() => onCloseClicked?.()} size="small">
        <X color={palette.primary.main} />
      </IconButton>
    </Box>
  );
};
