import { fjuelApi } from '@fjuel/configs/api';
import { IPC, IPCSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSiteIpcsOrderedByUse = createQuery<IPC[], { siteId: string | undefined }>({
  primaryKey: 'site-ipcsOrderedByUse',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    z.array(IPCSchema).parse(await fjuelApi.get(`site/${siteId}/ipcsOrderedByUse`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
});
