import { HarbourVessel, Vessel } from '@fjuel/schemas';
import { metersToLat, metersToLng } from '@fjuel/utils';

export const vesselToFeature = <TVessel extends Vessel | HarbourVessel>(
  vessel: TVessel
): GeoJSON.Feature<GeoJSON.Polygon, TVessel> => {
  const { location, length, width, trueHeading } = vessel as HarbourVessel;
  const lat = location?.lat ?? 0;
  const lng = location?.lng ?? 0;

  const angle = (-trueHeading * Math.PI) / 180;
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);

  const halfL = length / 2;
  const halfW = width / 2;
  const bow = length * 0.1; // 10% of length or min 10m

  const vesselPoints: [number, number][] = [
    [-halfW, -halfL], // back-left
    [halfW, -halfL], // back-right
    [halfW, halfL], // front-right
    [0, halfL + bow], // bow tip
    [-halfW, halfL], // front-left
    [-halfW, -halfL], // close
  ];

  const vesselShape: [number, number][] = vesselPoints.map(([x, y]): [number, number] => [
    lng + metersToLng(x * cos - y * sin, lat),
    lat + metersToLat(x * sin + y * cos),
  ]);

  return {
    type: 'Feature',
    geometry: { type: 'Polygon', coordinates: [vesselShape] },
    properties: vessel,
  };
};
