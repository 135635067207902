import { fjuelApi } from '@fjuel/configs/api';
import { minutesToMilliseconds } from 'date-fns';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';
import { SessionPeak, SessionPeakSchema } from '@fjuel/schemas/shorePowerConnect/sessions/SessionPeakSchema';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSessionPeak = createQuery<SessionPeak[], GetSessionQueryParams>({
  primaryKey: 'sessionpeak',
  queryFn: ({ queryKey: [_, variables] }) => getSessionPeak(variables),
  staleTime: minutesToMilliseconds(30),
  gcTime: minutesToMilliseconds(60),
  use: [disableIfVariablesUndefined('sessionId')],
});

type GetSessionQueryParams = {
  sessionId: string | undefined;
  from?: Date;
  to?: Date;
};

export const getSessionPeak = async ({ sessionId }: GetSessionQueryParams): Promise<SessionPeak[]> => {
  if (!sessionId) return [];
  const searchParams = new URLSearchParams();

  return z.array(SessionPeakSchema).parse(
    await fjuelApi
      .get(`session/${sessionId}/peak`, {
        searchParams,
      })
      .json()
  );
};
