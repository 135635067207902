import { Configuration, SilentRequest } from '@azure/msal-browser';
import { env } from '@fjuel/configs/env';

export const msalConfig: Configuration = {
  cache: {
    cacheLocation: 'localStorage',
  },
  auth: {
    clientId: env.VITE_MSAL_CLIENT_ID,
    redirectUri: '/auth/sign-in',
    postLogoutRedirectUri: '/auth/sign-out',
    authority: env.VITE_MSAL_AUTHORITY,
    knownAuthorities: [env.VITE_MSAL_KNOWN_AUTH],
  },
};

export const loginRequest: {
  scopes: SilentRequest['scopes'];
} = {
  scopes: [env.VITE_MSAL_SCOPE],
};
