import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useApplicationContext } from '../../context/ApplicationContext';
import { useSites } from '../../hooks';

export const SiteSelector: FC = () => {
  const { siteId: currentSiteId, setSiteId } = useApplicationContext();
  const { data: sites } = useSites();
  const location = useLocation();

  const navigate = useNavigate();
  const { siteId } = useApplicationContext();
  if (!sites) return null;
  if (sites.length === 1) {
    return (
      <Tooltip title={'Only access to one site'} placement="top" arrow enterDelay={500}>
        <TextField
          margin="normal"
          size="small"
          disabled
          sx={{ my: 2 }}
          label="Site"
          value={`Port of ${sites[0]!.name}`}
        />
      </Tooltip>
    );
  }

  // The presence of a second guid in the url indicates that we are working on a specific session, company or similar.
  // This will no longer be applicable when switching sites, and the second guid will therefore be stripped.
  function stripLastGuid(url: string): string {
    // Regular expression to match a second GUID at the end of the URL path.
    const regex = /\/[^/]+\/[a-fA-F0-9-]{36}$/;

    // Check if the pattern exists, and if so, strip the second GUID
    if (regex.test(url)) {
      return url.replace(regex, (match) => match.slice(0, match.lastIndexOf('/')));
    }

    // Return the original URL if no second GUID is detected
    return url;
  }

  return (
    <FormControl fullWidth sx={{ my: 2 }} size="small">
      <InputLabel id="site-select-label">Site</InputLabel>
      <Select
        labelId="site-select-label"
        label="Site"
        value={siteId ?? ''}
        onChange={(e) => {
          if (!currentSiteId) return;
          const newSiteId = e.target.value;
          setSiteId(newSiteId);
          return navigate(stripLastGuid(location.pathname).replace(currentSiteId, newSiteId));
        }}
        variant={'outlined'}
      >
        {sites?.map((site) => (
          <MenuItem key={site.id} value={site.id}>
            Port of {site.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
