import { FC, ReactNode, useState } from 'react';
import { Overlay } from '@fjuel/layout/Overlay';
import { AppBar } from '@fjuel/layout/AppBar';
import { Main } from '@fjuel/layout/Main';
import { Box, Container, styled } from '@mui/material';
import { SideMenu } from '@fjuel/components/SideMenu';

interface TabletLayoutProps {
  children: ReactNode;
}

const Sidebar = styled(Box)(({ isOpen }: { isOpen: boolean }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  width: isOpen ? 250 : 0,
  transition: 'width 0.3s ease',
  backgroundColor: 'hsla(225, 87%, 9%, 1)',
  zIndex: 1300,
  overflow: 'hidden',
}));

export const TabletLayout: FC<TabletLayoutProps> = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <Container maxWidth="xl">
      <Overlay onClick={() => setMenuOpen(false)} isActive={isMenuOpen} />
      <AppBar onClick={() => setMenuOpen(!isMenuOpen)} />
      <Main>{children}</Main>
      <Sidebar isOpen={isMenuOpen}>
        <SideMenu onClose={() => setMenuOpen(false)} />
      </Sidebar>
    </Container>
  );
};
