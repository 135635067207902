import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { SignIn, SignOut, User } from '@phosphor-icons/react';
import { FC, useState } from 'react';

import { useApplicationContext } from '../../context/ApplicationContext';

export const ProfileMenu: FC = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <UserMenuPopover />
      </AuthenticatedTemplate>{' '}
      <UnauthenticatedTemplate>
        <LoginButton />
      </UnauthenticatedTemplate>
    </>
  );
};
const LoginButton = () => {
  const { instance } = useMsal();
  return (
    <Button startIcon={<SignIn />} variant="outlined" onClick={() => void instance.loginRedirect()}>
      Login
    </Button>
  );
};
const UserMenuPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { accounts } = useMsal();
  const account = accounts[0];
  if (!account) return null;
  const given_name = account.idTokenClaims?.given_name as string | undefined;
  const name = given_name ?? account.username;
  const lastName = account.idTokenClaims?.family_name as string | undefined;
  return (
    <>
      <Button startIcon={<User />} variant="text" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Typography
          maxWidth={'15ch'}
          overflow={'hidden'}
          variant="button"
          textOverflow={'ellipsis'}
          textTransform={'none'}
        >
          {name}
        </Typography>
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={2}>
          <Typography variant="body1">
            {name} {lastName}
          </Typography>
          <LogoutButton />
        </Box>
      </Popover>
    </>
  );
};
const LogoutButton = () => {
  const { instance } = useMsal();
  const { setSiteId } = useApplicationContext();
  return (
    <Button
      variant="outlined"
      startIcon={<SignOut />}
      onClick={() => {
        setSiteId(undefined);
        return void instance.logoutRedirect();
      }}
    >
      Logout
    </Button>
  );
};
