import { Widget } from '@fjuel/components';
import { Typography } from '@mui/material';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { SignIn } from '@fjuel/pages/Auth/SignIn.tsx';
import { SignOut } from '@fjuel/pages/Auth/SignOut.tsx';
import { DeviceProvider } from '@fjuel/context/Device/DeviceProvider.tsx';
import { VesselEditProvider } from '@fjuel/pages/ShorePowerConnect/Vessels/VesselEditProvider/VesselEditProvider.tsx';
import { WidgetHeader } from '@fjuel/components/Global/WidgetHeader/WidgetHeader.tsx';

import { Layout } from '../layout/index.tsx';
import { MsalAppStateManager } from '../msal/MsalAppStateManager';
import { dashboardSiteDataLoader, sessionDataLoader, sitesDataLoader } from '../hooks';
import { queryClient } from '../configs/queryClient';
import { ApplicationContextProvider } from '../context/ApplicationContext.tsx';

import { ProtectedRoute } from './ProtectedRoute';
import { RouterErrorBoundary } from './RouterErrorBoundary.tsx';

export const router = createBrowserRouter([
  {
    path: 'auth',
    children: [
      { path: 'sign-in', element: <SignIn /> },
      { path: 'sign-out', element: <SignOut /> },
    ],
  },
  {
    path: '/',
    errorElement: <RouterErrorBoundary />,
    element: (
      <MsalAppStateManager>
        <ApplicationContextProvider>
          <DeviceProvider>
            <VesselEditProvider>
              <Layout>
                <Outlet />
              </Layout>
            </VesselEditProvider>
          </DeviceProvider>
        </ApplicationContextProvider>
      </MsalAppStateManager>
    ),
    children: [
      {
        index: true,
        lazy: async () => {
          const { SitePicker } = await import('../components/Dashboard/SitePicker.tsx');
          return {
            Component: SitePicker,
          };
        },
        loader: sitesDataLoader(queryClient),
      },
      {
        path: ':siteId',
        element: <Outlet />,
        children: [
          {
            index: true,
            lazy: async () => {
              const { DashboardPage } = await import('../pages/Dashboard/Dashboard.page.tsx');
              const { DashboardError } = await import('../pages/Dashboard/DashboardError.tsx');
              return {
                Component: DashboardPage,
                errorElement: <DashboardError />,
              };
            },
            loader: dashboardSiteDataLoader(queryClient),
          },
          {
            path: 'shore-power-units',
            lazy: async () => {
              const { ShorePowerUnitsOverviewPage } = await import(
                '../pages/ShorePowerConnect/ShorePowerUnits/ShorePowerUnitsOverview.page.tsx'
              );
              return { Component: ShorePowerUnitsOverviewPage };
            },
          },
          {
            path: 'vessel',
            element: <Outlet />,
            children: [
              {
                index: true,
                lazy: async () => {
                  const { VesselDashboardPage } = await import(
                    '../pages/ShorePowerConnect/Vessels/VesselDashboard.page.tsx'
                  );
                  return { Component: VesselDashboardPage };
                },
              },
              {
                path: ':id',
                lazy: async () => {
                  const { VesselPage } = await import('../pages/ShorePowerConnect/Vessels/Details/index.tsx');
                  return { Component: VesselPage };
                },
              },
            ],
          },
          {
            path: 'session',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { Sessions } = await import('../pages/ShorePowerConnect/Sessions/index.tsx');
                  return { Component: Sessions };
                },
              },
              {
                path: ':sessionId',
                lazy: async () => {
                  const { SessionDetailsPage } = await import(
                    '../pages/ShorePowerConnect/Sessions/components/SessionDetails/index.tsx'
                  );
                  return { Component: SessionDetailsPage };
                },
                loader: sessionDataLoader(queryClient),
              },
            ],
          },
          {
            path: 'price-models-general',
            lazy: async () => {
              const { SiteMarkupPage } = await import('../pages/PricingAndPayment/SiteMarkup.page.tsx');
              return { Component: (props) => <SiteMarkupPage {...props} itemType="general" /> };
            },
          },
          {
            path: 'price-models-specific',
            lazy: async () => {
              const { SiteMarkupPage } = await import('../pages/PricingAndPayment/SiteMarkup.page.tsx');
              return { Component: (props) => <SiteMarkupPage {...props} itemType="specific" /> };
            },
          },
          {
            path: 'companies',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { CompanyPage } = await import('../pages/ShorePowerConnect/Company/List/index.tsx');
                  return { Component: CompanyPage };
                },
              },
              {
                path: ':companyId',
                lazy: async () => {
                  const { CompanyDetailsPage } = await import('../pages/ShorePowerConnect/Company/Details/index.tsx');
                  return { Component: CompanyDetailsPage };
                },
              },
            ],
          },
          {
            path: 'reports',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { ReportPage } = await import('../pages/BusinessPerformance/Reports/Reports.page.tsx');
                  return { Component: ReportPage };
                },
              },
            ],
          },
          {
            path: 'port-call-planner',
            element: (
              <ProtectedRoute
                fallbackMessage={
                  <Widget>
                    <WidgetHeader>Not included in subscription</WidgetHeader>
                    <Typography>Upgrade subscription to access Port Call Planer</Typography>
                  </Widget>
                }
              >
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                lazy: async () => {
                  const { PortCallPlanerOverview } = await import(
                    '../pages/PortCallPlanner/PortCallPlanerOverview.page.tsx'
                  );
                  return { Component: PortCallPlanerOverview };
                },
              },
            ],
          },
          {
            path: 'energy-monitoring',
            lazy: async () => {
              const { EnergyMonitoringOverview } = await import(
                '../pages/EnergyMonitoring/EnergyMonitoringOverview.page'
              );
              return { Component: EnergyMonitoringOverview };
            },
          },
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            index: true,
            lazy: async () => {
              const { InvoiceOverviewPage } = await import('../pages/Invoice/List');
              return { Component: InvoiceOverviewPage };
            },
          },
          {
            path: ':invoiceId',
            lazy: async () => {
              const { InvoiceDetailsPage } = await import('../pages/Invoice/InvoiceDetails.page');
              return { Component: InvoiceDetailsPage };
            },
          },
        ],
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute allowedRoles={{ siteAdmin: true }}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            lazy: async () => {
              const { UsersPage } = await import('../pages/Users/List');
              return { Component: UsersPage };
            },
          },
        ],
      },
    ],
  },
  {
    path: 'type-documentation',
    lazy: async () => {
      const { TypeDocumentationPage } = await import('../pages/TypeDocumentation/TypeDocumentation.page.tsx');
      return { Component: TypeDocumentationPage };
    },
  },
]);
