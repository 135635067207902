import { AuthError } from '@azure/msal-browser';
import { loginRequest } from '@fjuel/msal/msal.config';
import { msalInstance } from '@fjuel/msal/msalInstance';
import ky from 'ky';

import { env } from './env';

export const fjuelApi = ky.create({
  prefixUrl: `${env.VITE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const publicClientApplication = await msalInstance;
        try {
          const accounts = publicClientApplication.getAllAccounts();
          const activeAccount = publicClientApplication.getActiveAccount();

          if (!activeAccount && accounts.length === 0) {
            try {
              await publicClientApplication.ssoSilent(loginRequest);
            } catch (err) {
              console.error(err);
            }
          }
          const token = await publicClientApplication.acquireTokenSilent({
            ...loginRequest,
            account: activeAccount || accounts[0],
          });
          if (!token.accessToken) throw new Error('No access token found');
          request.headers.set('Authorization', `Bearer ${token.accessToken}`);
        } catch (error) {
          if (error instanceof AuthError && error.errorMessage.includes('AADB2C90077')) {
            return publicClientApplication.acquireTokenRedirect(loginRequest);
          }
          if (error instanceof Error) console.error(error.message);
          else {
            console.error(error);
          }
          throw error;
        }
      },
    ],
  },
});
