import { ArrowDown, ArrowUp, ArrowsDownUp } from '@phosphor-icons/react';

import { TableSortingOrderIconProps } from './TableSortingOrderIcon.types';

export const TableSortingOrderIcon = ({ sortingOrder }: TableSortingOrderIconProps) => {
  if (sortingOrder === 'desc') {
    return <ArrowDown style={{ opacity: 0.5 }} size={16} />;
  }

  if (sortingOrder === 'asc') {
    return <ArrowUp style={{ opacity: 0.5 }} size={16} />;
  }

  return <ArrowsDownUp style={{ opacity: 0.5 }} size={16} />;
};
