import { SiteSelector } from '@fjuel/components/SideMenu/SiteSelector';
import { ProfileMenu } from '@fjuel/components/SideMenu/ProfileMenu';
import { ButtonNavLink } from '@fjuel/components/SideMenu/ButtonNavLink.tsx';
import { Users } from '@phosphor-icons/react';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useMe } from '@fjuel/hooks';

export const BottomBox: FC = () => {
  const { data: me } = useMe();

  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <SiteSelector />
      <ProfileMenu />
      {(me?.isSuperAdmin || me?.isSiteAdmin) && (
        <ButtonNavLink
          sx={{ mt: 2, whiteSpace: 'nowrap' }}
          variant="text"
          startIcon={<Users weight="duotone" />}
          to="/users"
        >
          Users admin
        </ButtonNavLink>
      )}
    </Box>
  );
};
