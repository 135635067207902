import { useMsal } from '@azure/msal-react';
import { MsalLoginWrapper } from '@fjuel/msal/MsalLoginWrapper';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactRouterMsalNavigationClient } from './ReactRouterMsalNavigationClient';

export const MsalAppStateManager: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const navigationClient = new ReactRouterMsalNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  return <MsalLoginWrapper>{children}</MsalLoginWrapper>;
};
