import { fjuelApi } from '@fjuel/configs/api';
import { Site, SiteSchema } from '@fjuel/schemas';
import { QueryClientLoaderFunction } from '@fjuel/types/QueryClientLoaderFunction';
import { createQuery } from 'react-query-kit';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSite = createQuery<Site, { id: string | undefined }>({
  primaryKey: 'site',
  queryFn: async ({ queryKey: [url, variables] }) => {
    if (!variables.id) throw new Error('Missing Id');
    return SiteSchema.parse(await fjuelApi.get(`${url}/${variables.id}`).json());
  },
  retry: false,
  use: [disableIfVariablesUndefined(['id'])],
});

export const dashboardSiteDataLoader: QueryClientLoaderFunction =
  (queryClient) =>
  async ({ params }) => {
    if (!params.siteId) return null;
    return queryClient.ensureQueryData({
      queryKey: useSite.getKey({ id: params.siteId }),
      queryFn: useSite.queryFn,
    });
  };
