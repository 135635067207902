import { ShorePowerUnit } from '@fjuel/schemas';
import { calculateMetersToDegreesAtLatitude, getStatusColor } from '@fjuel/utils';
import { METERS_TO_DEGREE } from '@fjuel/consts/geoConstants';

import { ShorePowerUnitActiveSession } from '../pages/ShorePowerConnect/ShorePowerUnits/ActiveSessionTable';

export const ShorePowerUnitToFeature = (
  shorePowerUnit: ShorePowerUnit
): GeoJSON.Feature<GeoJSON.Polygon, ShorePowerUnit & { color: string; height: number; 'base-height': 0 }> => {
  return {
    type: 'Feature',
    id: shorePowerUnit.id,
    geometry: {
      type: 'Polygon',
      coordinates: [sizeToPolygonCoordinates({ width: 500, length: 250 }, shorePowerUnit.location)],
    },
    properties: {
      ...shorePowerUnit,
      color: getStatusColor(shorePowerUnit.status),
      height: 250 / 100, // convert to meters
      'base-height': 0,
    },
  };
};

export const ActiveShorePowerUnitToFeature = (
  shorePowerUnit: ShorePowerUnitActiveSession
): GeoJSON.Feature<
  GeoJSON.Polygon,
  ShorePowerUnitActiveSession & { color: string; height: number; 'base-height': 0 }
> => {
  return {
    type: 'Feature',
    id: shorePowerUnit.id,
    geometry: {
      type: 'Polygon',
      coordinates: [sizeToPolygonCoordinates({ width: 500, length: 250 }, shorePowerUnit.location)],
    },
    properties: {
      ...shorePowerUnit,
      color: getStatusColor(shorePowerUnit.status),
      height: 250 / 100, // convert to meters
      'base-height': 0,
    },
  };
};

const sizeToPolygonCoordinates = (
  size: { width: number; length: number },
  coordinates: ShorePowerUnit['location']
): GeoJSON.Position[] => {
  const { width, length } = size;
  const { lat, lng } = coordinates ?? { lat: 0, lng: 0 };
  const widthInMeters = width / 100;
  const lengthInMeters = length / 100;
  const widthInDegrees = widthInMeters / 2 / METERS_TO_DEGREE;
  const lengthInDegrees = lengthInMeters / 2 / calculateMetersToDegreesAtLatitude(lat);
  const lat1 = lat + widthInDegrees / 2;
  const lng1 = lng + lengthInDegrees / 2;
  const lat2 = lat - widthInDegrees / 2;
  const lng2 = lng - lengthInDegrees / 2;
  const coordinatesArray: [number, number][] = [
    [lng1, lat1],
    [lng1, lat2],
    [lng2, lat2],
    [lng2, lat1],
    [lng1, lat1],
  ];
  return coordinatesArray;
};
