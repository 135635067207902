import { ButtonProps, Collapse, List } from '@mui/material';
import { FC } from 'react';

import { MenuTopLevelItem } from './MenuTopLevelItem';
import { ListItemNavLink } from './ListItemNavLink';
import { useMenuContext } from './MenuContext.ts';

export interface MenuItem {
  label: string;
  to: `/${string}`;
  icon: ButtonProps['startIcon'];
  disabled?: boolean;
}

interface CollapsibleMenuProps {
  id: 'shorePowerConnect' | 'pricingAndPaymentSolution' | 'businessPerformance' | 'portCallPlaner' | 'energyMonitoring';
  title: string;
  icon: ButtonProps['startIcon'];
  items: Array<MenuItem>;
  onMenuClose?: () => void;
}

export const CollapsibleMenu: FC<CollapsibleMenuProps> = ({ id, title, icon, items, onMenuClose }) => {
  const { openMenuItems, setOpenMenuItems } = useMenuContext();

  const closeAllBut = (key: keyof typeof openMenuItems) => {
    const toClose = Object.keys(openMenuItems).filter((k) => k !== key);
    const newState = toClose.reduce((acc, k) => ({ ...acc, [k]: false }), {});
    setOpenMenuItems((prev) => ({ ...prev, ...newState }));
    if (onMenuClose !== undefined) {
      onMenuClose();
    }
  };

  const toggleItem = (key: keyof typeof openMenuItems) => {
    setOpenMenuItems((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <>
      <MenuTopLevelItem onClick={() => toggleItem(id)} icon={icon} isOpen={openMenuItems[id]}>
        {title}
      </MenuTopLevelItem>
      <Collapse in={openMenuItems[id]}>
        <List disablePadding>
          {items.map((item, index) => (
            <ListItemNavLink
              key={index}
              indent
              startIcon={item.icon}
              to={item.to}
              onClick={() => closeAllBut(id)}
              disabled={item.disabled}
            >
              {item.label}
            </ListItemNavLink>
          ))}
        </List>
      </Collapse>
    </>
  );
};
