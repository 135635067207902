import { SortDirection } from '@tanstack/table-core';

export const getSortingOrderTitle = (sortingOrder: SortDirection | false) => {
  if (sortingOrder === 'desc') {
    return 'Descending';
  }

  if (sortingOrder === 'asc') {
    return 'Ascending';
  }

  return 'No sorting';
};
