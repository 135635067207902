import { BoxProps, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { CopyToClipboard } from './CopyToClipboard';

export const InlineStat: FC<{
  label: string;
  value: string;
  unit?: string;
  sx?: BoxProps['sx'];
  copyable?: boolean;
  showNotAvailableWhenZero?: boolean;
}> = ({ label, value, unit, sx, copyable = false, showNotAvailableWhenZero = false }) => {
  return (
    <Grid container alignItems="center" sx={sx}>
      <Grid item xs={6}>
        <Typography fontWeight={300} fontSize={12}>
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={'auto'} alignSelf="flex-end">
        {showNotAvailableWhenZero && (value === '0' || value === '0V') ? (
          'N/A'
        ) : (
          <Typography fontWeight={600} fontSize={14}>
            {value} {unit} {copyable && <CopyToClipboard text={value} />}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
