import { Ship } from '@fjuel/components/icons/Ship';
import { VesselTypes } from '@fjuel/schemas';
import { Anchor, Boat } from '@phosphor-icons/react';
import { FC, forwardRef } from 'react';
import { IconProps } from '@phosphor-icons/react';

export type VesselTypeIconProps = {
  vesselType: VesselTypes | undefined;
} & IconProps;

export const VesselTypeIcon: FC<VesselTypeIconProps> = forwardRef<SVGSVGElement, VesselTypeIconProps>(
  ({ vesselType, ...rest }, ref) => {
    if (vesselType === undefined) return null;
    switch (vesselType) {
      case VesselTypes.NotAvailable:
        return <Anchor aria-label="Ship type not available" {...rest} ref={ref} />;
      case VesselTypes.CargoAll:
        return <Ship {...rest} ref={ref} />;
      default:
        return <Boat {...rest} ref={ref} />;
    }
  }
);

VesselTypeIcon.displayName = 'VesselTypeIcon';
