import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { SourceLayers } from '@fjuel/components/SiteMap/Constants.ts';
import { useSiteAreaVessels } from '@fjuel/hooks';
import { vesselToFeature } from '@fjuel/mapbox-transformers/vesselToFeature.ts';

import { LayerProps } from './LayerProps.ts';
import { vesselFillPaint } from './vesselPaintStyles.ts';

export const VesselsLayer: FC<LayerProps> = ({ siteId }) => {
  const { data: vessels } = useSiteAreaVessels({
    variables: { siteId },
    select: (vessels) => vessels.map(vesselToFeature),
  });
  return (
    <Source id={SourceLayers.vessels} data={{ type: 'FeatureCollection', features: vessels ?? [] }} type="geojson">
      <Layer id={SourceLayers.vessels} type="fill" paint={vesselFillPaint} data-testid="vesselsLayer" />
    </Source>
  );
};
