import { VesselTypes } from '@fjuel/schemas';

export function getVesselTypeName(type: VesselTypes): string {
  switch (type) {
    case VesselTypes.NotAvailable:
      return 'Not Available';
    case VesselTypes.Reserved1:
    case VesselTypes.Reserved2:
    case VesselTypes.Reserved3:
    case VesselTypes.Reserved4:
    case VesselTypes.Reserved5:
    case VesselTypes.Reserved6:
    case VesselTypes.Reserved7:
    case VesselTypes.Reserved8:
    case VesselTypes.Reserved9:
    case VesselTypes.Reserved10:
    case VesselTypes.Reserved11:
    case VesselTypes.Reserved12:
    case VesselTypes.Reserved13:
    case VesselTypes.Reserved14:
    case VesselTypes.Reserved15:
    case VesselTypes.Reserved16:
    case VesselTypes.Reserved17:
    case VesselTypes.Reserved18:
    case VesselTypes.Reserved19:
      return 'Reserved 1-19';
    case VesselTypes.WingInGroundAll:
    case VesselTypes.WingInGroundHazardousA:
    case VesselTypes.WingInGroundHazardousB:
    case VesselTypes.WingInGroundHazardousC:
    case VesselTypes.WingInGroundHazardousD:
    case VesselTypes.WingInGroundReserved1:
    case VesselTypes.WingInGroundReserved2:
    case VesselTypes.WingInGroundReserved3:
    case VesselTypes.WingInGroundReserved4:
    case VesselTypes.WingInGroundReserved5:
      return 'Wing In Ground';
    case VesselTypes.Fishing:
      return 'Fishing';
    case VesselTypes.Towing:
    case VesselTypes.TowingExceeds200mOrBreadth:
      return 'Towing';
    case VesselTypes.DredgingOrUnderwaterOps:
      return 'Dredging or Underwater Operations';
    case VesselTypes.DivingOps:
      return 'Diving Operations';
    case VesselTypes.MilitaryOps:
      return 'Military Operations';
    case VesselTypes.Sailing:
      return 'Sailing';
    case VesselTypes.PleasureCraft:
      return 'Pleasure Craft';
    case VesselTypes.Reserved38:
    case VesselTypes.Reserved39:
      return 'Reserved 38-39';
    case VesselTypes.HighSpeedCraftAll:
    case VesselTypes.HighSpeedCraftHazardousA:
    case VesselTypes.HighSpeedCraftHazardousB:
    case VesselTypes.HighSpeedCraftHazardousC:
    case VesselTypes.HighSpeedCraftHazardousD:
    case VesselTypes.HighSpeedCraftReserved1:
    case VesselTypes.HighSpeedCraftReserved2:
    case VesselTypes.HighSpeedCraftReserved3:
    case VesselTypes.HighSpeedCraftReserved4:
    case VesselTypes.HighSpeedCraftNoAdditionalInfo:
      return 'High-Speed Craft';
    case VesselTypes.PilotVessel:
      return 'Pilot Vessel';
    case VesselTypes.SearchAndRescueVessel:
      return 'Search and Rescue Vessel';
    case VesselTypes.Tug:
      return 'Tug';
    case VesselTypes.PortTender:
      return 'Port Tender';
    case VesselTypes.AntiPollutionEquipment:
      return 'Anti-Pollution Equipment';
    case VesselTypes.LawEnforcement:
      return 'Law Enforcement';
    case VesselTypes.SpareLocalVessel1:
    case VesselTypes.SpareLocalVessel2:
      return 'Spare Local Vessel';
    case VesselTypes.MedicalTransport:
      return 'Medical Transport';
    case VesselTypes.NoncombatantShip:
      return 'Noncombatant Ship';
    case VesselTypes.PassengerAll:
    case VesselTypes.PassengerHazardousA:
    case VesselTypes.PassengerHazardousB:
    case VesselTypes.PassengerHazardousC:
    case VesselTypes.PassengerHazardousD:
    case VesselTypes.PassengerReserved1:
    case VesselTypes.PassengerReserved2:
    case VesselTypes.PassengerReserved3:
    case VesselTypes.PassengerReserved4:
    case VesselTypes.PassengerNoAdditionalInfo:
      return 'Passenger Ship';
    case VesselTypes.CargoAll:
    case VesselTypes.CargoHazardousA:
    case VesselTypes.CargoHazardousB:
    case VesselTypes.CargoHazardousC:
    case VesselTypes.CargoHazardousD:
    case VesselTypes.CargoReserved1:
    case VesselTypes.CargoReserved2:
    case VesselTypes.CargoReserved3:
    case VesselTypes.CargoReserved4:
    case VesselTypes.CargoNoAdditionalInfo:
      return 'Cargo Ship';
    case VesselTypes.TankerAll:
    case VesselTypes.TankerHazardousA:
    case VesselTypes.TankerHazardousB:
    case VesselTypes.TankerHazardousC:
    case VesselTypes.TankerHazardousD:
    case VesselTypes.TankerReserved1:
    case VesselTypes.TankerReserved2:
    case VesselTypes.TankerReserved3:
    case VesselTypes.TankerReserved4:
    case VesselTypes.TankerNoAdditionalInfo:
      return 'Tanker';
    case VesselTypes.OtherTypeAll:
    case VesselTypes.OtherTypeHazardousA:
    case VesselTypes.OtherTypeHazardousB:
    case VesselTypes.OtherTypeHazardousC:
    case VesselTypes.OtherTypeHazardousD:
    case VesselTypes.OtherTypeReserved1:
    case VesselTypes.OtherTypeReserved2:
    case VesselTypes.OtherTypeReserved3:
    case VesselTypes.OtherTypeReserved4:
    case VesselTypes.OtherTypeNoAdditionalInfo:
      return 'Other Type';
    default:
      return 'Unknown';
  }
}
