import { z } from 'zod';
import { fjuelApi } from '@fjuel/configs/api';
import { createQuery } from 'react-query-kit';
import { Filters } from '@fjuel/schemas/priceMarkup/FilterSchema.ts';
import { PriceMarkupArraySchema } from '@fjuel/schemas';
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined.ts';

export const useGetPriceMarkups = createQuery<z.infer<typeof PriceMarkupArraySchema>, { filters: Filters }>({
  primaryKey: 'priceMarkups',
  queryFn: async ({ queryKey: [, { filters }] }) => {
    const queryParams = serializeParameters(filters);
    const response = await fjuelApi.get(`priceMarkup?${queryParams}`).json();
    return PriceMarkupArraySchema.parse(response);
  },
  use: [disableIfVariablesUndefined(['filters'])],
});

const serializeParameters = (filters: Filters): string => {
  return new URLSearchParams(
    Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc[key] = String(value);
        }
        return acc;
      },
      {} as Record<string, string>
    )
  ).toString();
};
