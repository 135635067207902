import { z } from 'zod';

export enum VesselTypes {
  NotAvailable = 0,
  Reserved1 = 1,
  Reserved2 = 2,
  Reserved3 = 3,
  Reserved4 = 4,
  Reserved5 = 5,
  Reserved6 = 6,
  Reserved7 = 7,
  Reserved8 = 8,
  Reserved9 = 9,
  Reserved10 = 10,
  Reserved11 = 11,
  Reserved12 = 12,
  Reserved13 = 13,
  Reserved14 = 14,
  Reserved15 = 15,
  Reserved16 = 16,
  Reserved17 = 17,
  Reserved18 = 18,
  Reserved19 = 19,
  WingInGroundAll = 20,
  WingInGroundHazardousA = 21,
  WingInGroundHazardousB = 22,
  WingInGroundHazardousC = 23,
  WingInGroundHazardousD = 24,
  WingInGroundReserved1 = 25,
  WingInGroundReserved2 = 26,
  WingInGroundReserved3 = 27,
  WingInGroundReserved4 = 28,
  WingInGroundReserved5 = 29,
  Fishing = 30,
  Towing = 31,
  TowingExceeds200mOrBreadth = 32,
  DredgingOrUnderwaterOps = 33,
  DivingOps = 34,
  MilitaryOps = 35,
  Sailing = 36,
  PleasureCraft = 37,
  Reserved38 = 38,
  Reserved39 = 39,
  HighSpeedCraftAll = 40,
  HighSpeedCraftHazardousA = 41,
  HighSpeedCraftHazardousB = 42,
  HighSpeedCraftHazardousC = 43,
  HighSpeedCraftHazardousD = 44,
  HighSpeedCraftReserved1 = 45,
  HighSpeedCraftReserved2 = 46,
  HighSpeedCraftReserved3 = 47,
  HighSpeedCraftReserved4 = 48,
  HighSpeedCraftNoAdditionalInfo = 49,
  PilotVessel = 50,
  SearchAndRescueVessel = 51,
  Tug = 52,
  PortTender = 53,
  AntiPollutionEquipment = 54,
  LawEnforcement = 55,
  SpareLocalVessel1 = 56,
  SpareLocalVessel2 = 57,
  MedicalTransport = 58,
  NoncombatantShip = 59,
  PassengerAll = 60,
  PassengerHazardousA = 61,
  PassengerHazardousB = 62,
  PassengerHazardousC = 63,
  PassengerHazardousD = 64,
  PassengerReserved1 = 65,
  PassengerReserved2 = 66,
  PassengerReserved3 = 67,
  PassengerReserved4 = 68,
  PassengerNoAdditionalInfo = 69,
  CargoAll = 70,
  CargoHazardousA = 71,
  CargoHazardousB = 72,
  CargoHazardousC = 73,
  CargoHazardousD = 74,
  CargoReserved1 = 75,
  CargoReserved2 = 76,
  CargoReserved3 = 77,
  CargoReserved4 = 78,
  CargoNoAdditionalInfo = 79,
  TankerAll = 80,
  TankerHazardousA = 81,
  TankerHazardousB = 82,
  TankerHazardousC = 83,
  TankerHazardousD = 84,
  TankerReserved1 = 85,
  TankerReserved2 = 86,
  TankerReserved3 = 87,
  TankerReserved4 = 88,
  TankerNoAdditionalInfo = 89,
  OtherTypeAll = 90,
  OtherTypeHazardousA = 91,
  OtherTypeHazardousB = 92,
  OtherTypeHazardousC = 93,
  OtherTypeHazardousD = 94,
  OtherTypeReserved1 = 95,
  OtherTypeReserved2 = 96,
  OtherTypeReserved3 = 97,
  OtherTypeReserved4 = 98,
  OtherTypeNoAdditionalInfo = 99,
}

export const VesselTypeSchema = z.nativeEnum(VesselTypes).default(VesselTypes.NotAvailable);
