import { fjuelApi } from '@fjuel/configs/api';
import { ShorePowerUnitSchema } from '@fjuel/schemas/shorePowerConnect/shorePowerUnits/ShorePowerUnitSchema';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

const ShorePowerUnitsSchema = z.array(ShorePowerUnitSchema);

export const useShorePowerUnits = createQuery<z.infer<typeof ShorePowerUnitsSchema>, { siteId: string | undefined }>({
  primaryKey: 'site-shorepowerunits',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    ShorePowerUnitsSchema.parse(await fjuelApi.get(`site/${siteId}/shorepowerunits`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
  retry: false,
});
