import { z } from 'zod';

import { checkDuplicateUpperLimits, EffectMarkupCommonSchema } from './EffectMarkupCommonSchema.ts';

export const EffectMarkupCreateSchema = EffectMarkupCommonSchema;

export type EffectMarkupCreate = z.infer<typeof EffectMarkupCreateSchema>;

export const EffectMarkupsCreateSchema = z.array(EffectMarkupCreateSchema).superRefine((data, ctx) => {
  checkDuplicateUpperLimits(data, ctx);
});
