import { z } from 'zod';
import { PriceMarkupTypeSchema } from '@fjuel/schemas';

export const PriceMarkupCommonSchema = z.object({
  siteId: z.string().uuid().describe('Identifier of the site to which the price markup belongs'),

  ipcId: z.string().uuid().nullable().optional().describe('Identifier of the IPC, if applicable'),

  siteCompanyId: z.string().uuid().nullable().optional().describe('Identifier of the site company, if applicable'),

  validFrom: z.coerce.date().nullable().optional().describe('The start date from which the price markup is valid'),

  validTo: z.coerce.date().nullable().optional().describe('The end date until which the price markup is valid'),

  hidden: z.boolean().describe('Indicates whether the price markup is hidden'),

  priceMarkupType: PriceMarkupTypeSchema,

  cableHandling: z.coerce.number().nonnegative().describe('The price markup for cable handling'),

  perKwh: z.coerce.number().nonnegative().describe('The price markup per kWh'),
});
