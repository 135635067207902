import { fjuelApi } from '@fjuel/configs/api';
import { HarbourVessel, HarbourVesselSchema, Vessel, VesselSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSiteAreaVessels = createQuery<HarbourVessel[], { siteId: string | undefined }>({
  primaryKey: 'site-area-vessels',
  queryFn: async ({ queryKey: [_, variables] }) =>
    z.array(HarbourVesselSchema).parse(await fjuelApi.get(`site/${variables.siteId}/vessels`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
});

export const useSiteVessels = createQuery<Vessel[], { siteId: string | undefined }>({
  primaryKey: 'site-vessels',
  queryFn: async ({ queryKey: [_, variables] }) =>
    z.array(VesselSchema).parse(await fjuelApi.get(`site/${variables.siteId}/vessels/historical`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
});

export const useArrivingVessels = createQuery<HarbourVessel[], { siteId: string | undefined }>({
  primaryKey: 'arriving-vessels',
  queryFn: async ({ queryKey: [_, variables] }) =>
    z.array(HarbourVesselSchema).parse(await fjuelApi.get(`site/${variables.siteId}/arrivals`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
});
