import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { IMsalContext, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { Box, CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { CenteredContent } from '../components/Global/CenteredContent';
import FjuelLogo from '../assets/icons/fjuel.svg?react';

import { loginRequest } from './msal.config';

export const MsalLoginWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { accounts, inProgress } = useMsal();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      loadingComponent={LoadingLogin}
      errorComponent={ErrorLogin}
      authenticationRequest={{
        ...loginRequest,
        redirectStartPage: window.location.href,
      }}
    >
      {inProgress !== InteractionStatus.None ? (
        <CircularProgress color="primary" />
      ) : !accounts || accounts.length === 0 ? (
        <CircularProgress color="info" />
      ) : (
        children
      )}
    </MsalAuthenticationTemplate>
  );
};
const LoadingLogin: FC<IMsalContext> = ({ inProgress }) => {
  const color: CircularProgressProps['color'] =
    inProgress === InteractionStatus.HandleRedirect
      ? 'warning'
      : inProgress === InteractionStatus.None
        ? 'secondary'
        : 'primary';

  const authenticationStage =
    inProgress === InteractionStatus.HandleRedirect
      ? 'Redirecting...'
      : inProgress === InteractionStatus.None
        ? 'Loading...'
        : inProgress === InteractionStatus.Logout
          ? 'Logging out...'
          : inProgress === InteractionStatus.AcquireToken
            ? 'Acquiring token...'
            : inProgress === InteractionStatus.Login
              ? 'Logging in...'
              : 'Authenticating...';

  return (
    <Box display={'grid'} justifyContent={'center'} alignItems={'center'} minHeight={'100dvh'}>
      <Stack alignItems={'center'}>
        <FjuelLogo />
        <Typography mb={3} variant="h3" component={'h1'}>
          {authenticationStage}
        </Typography>
        <CircularProgress color={color} />
      </Stack>
    </Box>
  );
};
const ErrorLogin: FC = () => (
  <CenteredContent>
    <Box>
      <FjuelLogo />
      <Typography variant="h3" component="h1">
        Something went wrong
      </Typography>
      <Typography>We had trouble logging you in. Please try again later.</Typography>
    </Box>
  </CenteredContent>
);
