import { FC } from 'react';
import { VesselTypes } from '@fjuel/schemas';
import { Tooltip } from '@mui/material';
import { getVesselTypeName } from '@fjuel/utils';
import { VesselTypeIcon } from '@fjuel/components/icons/VesselTypeIcon';

interface VesselTypeIconWithTooltipProps {
  vesselType: VesselTypes;
  size: number;
}

export const VesselTypeIconWithTooltip: FC<VesselTypeIconWithTooltipProps> = ({ vesselType, ...rest }) => {
  return (
    <Tooltip title={getVesselTypeName(vesselType)} placement="top" arrow>
      <VesselTypeIcon vesselType={vesselType} {...rest} />
    </Tooltip>
  );
};
