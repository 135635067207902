import { fjuelApi } from '@fjuel/configs/api';
import { Session, SessionSchema } from '@fjuel/schemas';
import { QueryClientLoaderFunction } from '@fjuel/types/QueryClientLoaderFunction';
import { QueryClient } from '@tanstack/react-query';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

import { disableIfVariablesUndefined } from '../queries/middlewares/disableIfVariablesUndefined';

import { useVessel } from '.';

export const useSiteSessions = createQuery<Session[], { siteId: string | undefined; limit?: number }>({
  primaryKey: 'site-sessions',
  queryFn: async ({ queryKey: [_, { siteId, limit }] }) => {
    const searchParams = new URLSearchParams();
    if (limit) {
      searchParams.set('limit', limit.toString());
    }
    const result = await fjuelApi.get(`site/${siteId}/sessions`, { searchParams });
    const json = await result.json();
    return z.array(SessionSchema).parse(json);
  },
  refetchOnWindowFocus: false,
  use: [disableIfVariablesUndefined('siteId')],
});

export const sessionDataLoader: QueryClientLoaderFunction =
  (queryClient) =>
  async ({ params: { siteId } }) => {
    if (!siteId) return null;
    return queryClient.ensureQueryData({
      queryKey: useSiteSessions.getKey({ siteId }),
      queryFn: async (args) => {
        const result = await useSiteSessions.queryFn({ ...args });
        await Promise.all(result.map((x) => (x.vesselId != null ? ensureVesselData(queryClient) : '')));
        return result;
      },
    });
  };

const ensureVesselData = (queryClient: QueryClient) => (session: Session) =>
  queryClient.ensureQueryData({
    queryKey: useVessel.getKey({ id: session.vesselId! }),
    queryFn: useVessel.queryFn,
  });
