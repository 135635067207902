import { useApplicationContext } from '@fjuel/context/ApplicationContext';
import { env } from '@fjuel/configs/env.ts';
import { ShorePowerUnitActiveSession } from '@fjuel/pages/ShorePowerConnect/ShorePowerUnits/ActiveSessionTable';
import { HarbourVessel, IPC } from '@fjuel/schemas';
import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Map as MapGL, MapProps, MapRef, NavigationControl, ViewState } from 'react-map-gl';
import { BuildingsLayer, IpcsLayer, SpusLayer, VesselsLayer } from '@fjuel/components/SiteMap/Layers';
import { SourceLayers } from '@fjuel/components/SiteMap/Constants.ts';

import { PopOver, PopupDetails } from './PopOver';

export type SiteMapProps = {
  onMove?: MapProps['onMove'];
  showLabels?: boolean;
  viewState: Omit<ViewState, 'padding'>;
  testId?: string;
} & Pick<MapProps, 'style'>;

export type SiteMapRef = {
  resize?: VoidFunction;
};

export const SiteMap = forwardRef<SiteMapRef, SiteMapProps>(function SiteMap(
  { onMove, viewState, style, showLabels = false, testId },
  ref
) {
  const { siteId } = useApplicationContext();

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.resize(); // Trigger resize
    }
  }, [viewState, style]); // Dependencies that might affect the map size

  const mapRef = useRef<MapRef | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      resize: () => mapRef.current?.resize(),
    }),
    []
  );

  useEffect(() => {
    const map = mapRef.current;
    return () => {
      imagesToLoad.forEach(({ id }) => {
        if (!map) return;
        map?.removeImage?.(id);
      });
    };
  }, []);

  const [popOverDetails, setPopOverDetails] = useState<PopupDetails | null>(null);

  const handleOnClick: MapProps['onClick'] = (e) => {
    if (!e.features) return;
    const feature = e.features[0];
    if (!feature) return;
    if (!feature.properties) return;

    switch (feature.layer.id as keyof typeof SourceLayers) {
      case SourceLayers.ipcs:
        return setPopOverDetails({
          type: 'ipc',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          ...(feature.properties as IPC),
        });
      case SourceLayers.shorePowerUnits:
      case SourceLayers.shorePowerUnitsNotCharging:
        return setPopOverDetails({
          type: 'shorePowerUnit',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          ...(feature.properties as ShorePowerUnitActiveSession),
        });
      case SourceLayers.vessels:
        return setPopOverDetails({
          type: 'vessel',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          id: feature.properties.id as HarbourVessel['id'],
          name: feature.properties.name as string,
          status: feature.properties.status as HarbourVessel['status'],
          imo: feature.properties.imo as HarbourVessel['imo'],
          frequencyHz: feature.properties.frequencyHz as HarbourVessel['frequencyHz'],
          voltageV: feature.properties.voltageV as HarbourVessel['voltageV'],
        });
    }
  };

  return (
    <Box
      component="div"
      sx={{
        '& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
          borderColor: 'transparent',
          borderLeftColor: '#0f1d45',
        },
        '& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
          borderColor: 'transparent',
          borderRightColor: '#0f1d45',
        },
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setPopOverDetails(null);
        }
      }}
      style={{ ...style }}
      data-testid={testId ?? 'SiteMap'}
    >
      <MapGL
        onLoad={(e) => {
          const map = e.target;
          if (!map) return;
          imagesToLoad.forEach(({ src, id }) => {
            map?.loadImage(src, (error, image) => {
              if (error) throw error;
              if (!map) return;
              if (!image) return;
              if (map.hasImage(id)) return;
              map.addImage(id, image);
            });
          });
        }}
        key={siteId}
        attributionControl={false}
        logoPosition="top-right"
        ref={mapRef}
        {...viewState}
        interactiveLayerIds={['area', ...Object.keys(SourceLayers)]}
        mapboxAccessToken={env.VITE_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/steffenholanger/clje9twhn005201peehih699c"
        initialViewState={{
          latitude: 67.28350893,
          longitude: 14.37456511,
          zoom: 15,
        }}
        onMouseEnter={() => {
          if (mapRef.current) {
            mapRef.current.getCanvas().style.cursor = 'pointer';
          }
        }}
        onMouseLeave={() => {
          if (mapRef.current) {
            mapRef.current.getCanvas().style.cursor = '';
          }
        }}
        onClick={handleOnClick}
        onMove={onMove}
      >
        <NavigationControl showCompass showZoom={false} visualizePitch />
        <BuildingsLayer />
        <VesselsLayer siteId={siteId} showLabels={showLabels} />
        <IpcsLayer siteId={siteId} showLabels={showLabels} />
        <SpusLayer siteId={siteId} showLabels={showLabels} />
        {popOverDetails && <PopOver popOverDetails={popOverDetails} setPopOverDetails={setPopOverDetails} />}
      </MapGL>
    </Box>
  );
});

const imagesToLoad: readonly { src: string; id: string }[] = [
  {
    src: '/Boat-small.png',
    id: 'small-boat',
  },
  {
    src: '/IPC.png',
    id: 'IPC',
  },
  {
    src: '/ShorePowerUnit.png',
    id: 'shore-power-unit',
  },
  {
    src: '/ShorePowerUnit-not-charging.png',
    id: 'shore-power-unit-not-charging',
  },
] as const;
