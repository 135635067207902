import React from 'react';
import { DesktopLayout } from '@fjuel/layout/DesktopLayout.tsx';
import { TabletLayout } from '@fjuel/layout/TabletLayout.tsx';

import { useDevice } from '../context/Device/DeviceContext.ts';

import { PhoneLayout } from './PhoneLayout.tsx';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isPhone, isTablet } = useDevice();

  if (isPhone) {
    return <PhoneLayout>{children}</PhoneLayout>;
  } else if (isTablet) {
    return <TabletLayout>{children}</TabletLayout>;
  } else {
    return <DesktopLayout>{children}</DesktopLayout>;
  }
};
