import { z } from 'zod';

import { LocationSchema } from '../utils/LocationSchema';

export const SiteSchema = z.object({
  id: z.string(),
  name: z.string(),
  location: LocationSchema,
  mapView: z
    .object({
      pitch: z.number().min(0).max(90).default(0).describe('Pitch in degrees'),
      bearing: z.number().min(-360).max(360).default(0).describe('Bearing in degrees'),
      zoom: z.number().min(0).max(22).default(14).describe('Zoom level'),
    })
    .default({}),
});

export type Site = z.infer<typeof SiteSchema>;
