import { EffectMarkupsCreateSchema } from '@fjuel/schemas';
import { z } from 'zod';

import { PriceMarkupCommonSchema } from './PriceMarkupCommonSchema.ts';

export const PriceMarkupCreateSchema = PriceMarkupCommonSchema.extend({
  effectMarkups: EffectMarkupsCreateSchema,
});

export type PriceMarkupCreate = z.infer<typeof PriceMarkupCreateSchema>;
