import { fjuelApi } from '@fjuel/configs/api';
import { UserWithPermissionSchema } from '@fjuel/schemas/users/UserWithPermissionSchema';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

export const useUsers = createQuery({
  primaryKey: 'users',
  queryFn: async () => {
    try {
      const response = await fjuelApi.get('User');
      const jsonResponse = await response.json();
      return z.array(UserWithPermissionSchema).parse(jsonResponse);
    } catch (error) {
      console.error('Parsing or API request failed:', error);
      throw error;
    }
  },
});
