import { LogoBox } from '@fjuel/components/SideMenu/LogoBox.tsx';
import { Box, Button, styled } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { useDevice } from '@fjuel/context/Device/DeviceContext';
import { FC } from 'react';

interface HeaderProps {
  onClose?: () => void;
}

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'auto',
  justifyContent: 'space-between',
  p: 2,
  width: '100%',
  height: '64px',
  position: 'sticky',
  top: 0,
}));

const DesktopHeader = () => {
  return <LogoBox />;
};

const TabletHeader: FC<HeaderProps> = ({ onClose }) => {
  return (
    <StyledBox>
      <Box />
      <Button onClick={onClose} endIcon={<X weight="duotone" />} color="inherit" sx={{ padding: 2 }} />
    </StyledBox>
  );
};

const PhoneHeader: FC<HeaderProps> = ({ onClose }) => {
  return (
    <StyledBox>
      <Box sx={{ width: '128px', height: '48px' }}>
        <LogoBox onMenuClose={onClose} />
      </Box>
      <Button onClick={onClose} endIcon={<X weight="duotone" />} color="inherit" sx={{ padding: 2 }} />
    </StyledBox>
  );
};

export const Header: FC<HeaderProps> = ({ onClose }) => {
  const { isPhone, isTablet } = useDevice();

  if (isPhone) {
    return <PhoneHeader onClose={onClose} />;
  } else if (isTablet) {
    return <TabletHeader onClose={onClose} />;
  } else {
    return <DesktopHeader />;
  }
};
