import { HarbourVessel } from '@fjuel/schemas';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { ComponentProps, FC } from 'react';

import SmallBoat from '../../assets/icons/Boat-front.svg?react';
import { InlineStat } from '../Global/InlineStat';

type VesselPopoverContent = Pick<HarbourVessel, 'id' | 'name' | 'status' | 'imo' | 'frequencyHz' | 'voltageV'>;

export const VesselPopover: FC<{
  vessel: VesselPopoverContent;
  onSeeDetailsClicked?: (vessel: Required<VesselPopoverContent>) => void;
  onCloseClicked?: () => void;
}> = ({ vessel, onCloseClicked, onSeeDetailsClicked }) => {
  const { palette } = useTheme();
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box display="flex" gap={1} alignItems={'center'} whiteSpace={'nowrap'}>
        <Typography whiteSpace={'normal'} fontWeight={600} mr={'auto'}>
          {vessel.name ?? 'Missing name'}
        </Typography>
        {vessel.id && (
          <Button
            sx={{ textTransform: 'none', borderRadius: 2, px: 2.5, py: 0.5 }}
            variant="outlined"
            onClick={() => onSeeDetailsClicked?.(vessel)}
          >
            See details
          </Button>
        )}
        <IconButton onClick={() => onCloseClicked?.()} size="small">
          <X color={palette.primary.main} />
        </IconButton>
      </Box>
      <Box display="grid" gridTemplateColumns={'1fr 3fr'} gap={2}>
        <Box bgcolor={'hsla(224, 55%, 22%, 1)'} borderRadius={1} p={0.2}>
          <SmallBoat style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box>
          <InlineStat label={'IMO number'} value={vessel.imo ?? 'No IMO number'} copyable={!!vessel.imo} />
          <InlineStat label={'Frequency'} value={vessel.frequencyHz} showNotAvailableWhenZero={true} />
          <InlineStat label={'Voltage'} value={vessel.voltageV} showNotAvailableWhenZero={true} />
        </Box>
      </Box>
    </Box>
  );
};

export type VesselPopoverProps = ComponentProps<typeof VesselPopover>;
