import { fjuelApi } from '@fjuel/configs/api';
import { KPI, KPIResponseSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useVesselKpiForPeriod = createQuery<
  KPI,
  { vesselId: string | undefined; period: 'monthly' | 'yearly' | 'daily' }
>({
  primaryKey: 'vesselKpi',
  queryFn: async ({ queryKey: [, { vesselId, period }] }) =>
    KPIResponseSchema.parse(await fjuelApi.get(`vessel/${vesselId}/kpi/${period}`).json()),
  use: [disableIfVariablesUndefined('vesselId')],
});

export type useKpiResult =
  | {
      isPending: false;
      error: string;
      yearly: undefined;
      monthly: undefined;
      daily: undefined;
    }
  | {
      isPending: false;
      error: undefined;
      yearly: KPI;
      monthly: KPI;
      daily: KPI;
    }
  | {
      isPending: true;
      error: undefined;
      yearly: undefined;
      monthly: undefined;
      daily: undefined;
    };

export const useVesselKpi = (vesselId: string | undefined): useKpiResult => {
  const {
    isError: yearlyError,
    data: yearly,
    isPending: isLoadingYearly,
  } = useVesselKpiForPeriod({
    variables: { vesselId, period: 'yearly' },
  });
  const {
    isError: monthlyError,
    data: monthly,
    isPending: isLoadingMonthly,
  } = useVesselKpiForPeriod({
    variables: { vesselId, period: 'monthly' },
  });
  const {
    isError: dailyError,
    data: daily,
    isPending: isLoadingDaily,
  } = useVesselKpiForPeriod({ variables: { vesselId, period: 'daily' } });

  if (isLoadingYearly || isLoadingMonthly || isLoadingDaily) {
    return {
      isPending: true,
      error: undefined,
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    };
  }

  if (yearlyError || monthlyError || dailyError) {
    return {
      isPending: false,
      error: 'Error fetching vessel KPI',
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    };
  }

  return {
    isPending: false,
    error: undefined,
    yearly,
    monthly,
    daily,
  };
};
