import { fjuelApi } from '@fjuel/configs/api';
import { PriceMarkupSchema, PriceMarkupUpdateSchema } from '@fjuel/schemas/priceMarkup';
import { createMutation } from 'react-query-kit';
import { PriceMarkupUpdate } from '@fjuel/schemas/priceMarkup/PriceMarkupUpdateSchema.ts';

export const useUpdatePriceMarkup = createMutation({
  mutationFn: async ({ priceMarkup }: { priceMarkup: PriceMarkupUpdate }) => {
    const body = PriceMarkupUpdateSchema.parse(priceMarkup);
    const response = await fjuelApi.put(`priceMarkup`, { json: body }).json();
    return PriceMarkupSchema.parse(response);
  },
});
