import { z } from 'zod';

import { ActiveSessionSchema } from '../sessions/ActiveSessionSchema';
import { IPCSchema } from '../../ipc/IPCSchema';

const Base = IPCSchema.describe('IPC').extend({ activeSessions: z.array(ActiveSessionSchema) });

export const ShorePowerUnitsOverviewSchema = z.array(Base);

export type ShorePowerUnitsOverview = z.infer<typeof ShorePowerUnitsOverviewSchema>;
