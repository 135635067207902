import { coerceStringWithSuffix } from '@fjuel/utils';
import { z } from 'zod';
import { Frequency } from '@fjuel/schemas/shorePowerConnect/vessels/Frequency';

const pre = (v: unknown) => {
  let coercedValue;

  if (typeof v === 'number') {
    coercedValue = `${v}Hz`;
  } else if (typeof v === 'string' && v === '') {
    coercedValue = coerceStringWithSuffix('Hz')(0);
  } else if (v == null) {
    coercedValue = coerceStringWithSuffix('Hz')(0);
  } else {
    coercedValue = coerceStringWithSuffix('Hz')(v);
  }

  // Fall back to Frequency.Zero if the coerced value is not recognized
  if (!Object.values(Frequency).includes(coercedValue)) {
    return Frequency.Zero;
  }

  return coercedValue;
};

export const FrequencySchema = z.preprocess(pre, z.enum([Frequency.Zero, Frequency.Fifty, Frequency.Sixty]));
