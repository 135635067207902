import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  useTheme,
} from '@mui/material';
import { Table as TableType, flexRender } from '@tanstack/react-table';
import { CSSProperties, forwardRef, useRef } from 'react';

import { EmptyTable } from './EmptyTable';
import { FilterMenu } from './FilterMenu';
import { TablePagination } from './TablePagination';
import { stickyStyles, tableRowBgColor } from './stickyStyles';
import { getSortingOrderTitle } from './utils/getSortingOrderTitle';
import { TableSortingOrderIcon } from './components/TableSortingOrderIcon/TableSortingOrderIcon';

export type MUITableProps<
  TableData extends object & {
    delete?: boolean;
  },
> = {
  table: TableType<TableData>;
  widthMaxContent?: boolean;
  paginationSiblingCount?: number;
  emptyTableLabel?: string;
  footer?: React.ReactNode;
  disablePagination?: boolean;
  sx?: SxProps;
};

export const MUITable = <TableData extends object & { delete?: boolean }>({
  table,
  widthMaxContent = false,
  paginationSiblingCount,
  emptyTableLabel,
  footer,
  sx,
  disablePagination = false,
}: MUITableProps<TableData>) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  const dataRowRef = useRef<HTMLTableRowElement>(null);

  return (
    <>
      <MUITableContainer table={table} widthMaxContent={widthMaxContent} rowHeight={dataRowRef.current?.offsetHeight}>
        <Table stickyHeader sx={sx}>
          <MUITableHead table={table} />
          <MUITableBody table={table} emptyTableLabel={emptyTableLabel} ref={dataRowRef} />
          <TableFooter>{footer}</TableFooter>
        </Table>
      </MUITableContainer>
      {!disablePagination && (
        <TablePagination
          siblingCount={paginationSiblingCount}
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={(page) => table.setPageIndex(page)}
          onRowsPerPageChange={(e) => table.setPageSize(e)}
        />
      )}
    </>
  );
};
const rowDefaultHeight = 42;

export const MUITableContainer = <TableData extends object & { delete?: boolean }>({
  table,
  children,
  widthMaxContent,
  rowHeight = rowDefaultHeight,
}: {
  rowHeight?: number;
  children: React.ReactNode;
  widthMaxContent?: boolean;
  table: TableType<TableData>;
}) => {
  const { pageSize } = table.getState().pagination;

  const length = table.getFilteredRowModel().rows.length;
  const headerOffset = 58;

  const minHeight = pageSize < length ? rowHeight * pageSize + headerOffset : undefined;
  return (
    <TableContainer
      sx={{
        width: widthMaxContent ? 'max-content' : 'unset',
        maxHeight: '80dvh',
        minHeight,
      }}
    >
      {children}
    </TableContainer>
  );
};

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const MUITableBodyEl = <TableData extends object & { delete?: boolean }>(
  {
    table,
    emptyTableLabel,
  }: {
    table: TableType<TableData>;
    emptyTableLabel?: string;
  },
  ref?: React.Ref<HTMLTableRowElement>
) => {
  const { spacing } = useTheme();

  const allRows = table.getPrePaginationRowModel().rows;

  const ongoingSessions = allRows.filter((row) =>
    row.getAllCells().some((cell) => cell.id === `${row.id}_timeEnded` && cell.getValue() === null)
  );

  const remainingRows = allRows.filter((row) => !ongoingSessions.includes(row));

  const isAscending = table.getState().sorting[0]?.desc === false;

  const sortedRows = !isAscending ? [...ongoingSessions, ...remainingRows] : [...remainingRows, ...ongoingSessions];

  const { pageIndex, pageSize } = table.getState().pagination;

  const paginatedRows = sortedRows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  return (
    <TableBody>
      {table.getFilteredRowModel().rows.length === 0 ? (
        <EmptyTable table={table} label={emptyTableLabel ?? 'No data'} />
      ) : (
        paginatedRows.map((row) => (
          <StyledTableRow key={row.id} ref={ref}>
            {row.getVisibleCells().map((cell, index, array) => {
              const deleteRowStyled: CSSProperties = {};
              if (cell.row.original.delete) {
                const borderRadius = spacing(1);
                deleteRowStyled.borderTop = '1px solid red';
                deleteRowStyled.borderBottom = '1px solid red';
                deleteRowStyled.textDecoration = 'line-through';

                if (index === 0) {
                  deleteRowStyled.borderLeft = '1px solid red';
                  deleteRowStyled.borderRadius = `${borderRadius} 0 0 ${borderRadius}`;
                }

                if (index === array.length - 1) {
                  deleteRowStyled.borderRight = '1px solid red';
                  deleteRowStyled.borderRadius = `0 ${borderRadius} ${borderRadius} 0`;
                }
              }

              const stickySx = cell.column.columnDef.meta?.sticky ? stickyStyles : {};

              return (
                <StyledTableCell
                  key={cell.id}
                  sx={{
                    width: cell.column.getSize(),
                    ...deleteRowStyled,
                    ...stickySx,
                  }}
                  align={cell.column.columnDef.meta?.align}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        ))
      )}
    </TableBody>
  );
};

export const MUITableBody = forwardRef(MUITableBodyEl);

export const MUITableHead = <TableData extends object & { delete?: boolean }>({
  table,
}: {
  table: TableType<TableData>;
}) => {
  return (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const meta = header.column.columnDef.meta;
            const sx = meta?.sticky ? stickyStyles : {};
            const sortingOrder = header.column.getIsSorted();
            const sortingOrderHint = getSortingOrderTitle(sortingOrder);
            return (
              <StyledTableCell
                key={header.id}
                sx={{
                  ...sx,
                  textAlign: meta?.align,
                  width: header.getSize(),
                  cursor: header.column.getCanSort() ? 'pointer' : 'unset',
                }}
                onClick={header.column.getToggleSortingHandler()}
                align={meta?.align}
                title={sortingOrderHint}
              >
                <Box
                  fontWeight={600}
                  fontSize={16}
                  display="flex"
                  alignItems="center"
                  gap={1}
                  justifyContent={meta?.align || 'start'}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {meta?.toggleIncludeFilterOptions && (
                    <FilterMenu column={header.column} options={meta.toggleIncludeFilterOptions} />
                  )}
                  <TableSortingOrderIcon sortingOrder={sortingOrder} />
                </Box>
              </StyledTableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    color: theme.palette.common.white,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderColor: 'rgba(255, 255, 255, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    border: 'none',
    letterSpacing: '0.28px',
    whiteSpace: 'nowrap',
    fontSize: 14,
    'button, a': { padding: 0 },
    '.access-selector': { padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}` },
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: `var(${tableRowBgColor}, 'inherit')`,
  '&:nth-of-type(odd)': {
    '--table-row-bg-color': 'hsl(225, 55%, 22%)',
  },
}));
