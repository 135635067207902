import type { Expression } from 'mapbox-gl';

export const vesselFillPaint: {
  'fill-color': Expression;
  'fill-opacity': Expression;
  'fill-outline-color': string;
} = {
  'fill-color': [
    'case',
    ['<', ['get', 'length'], 13],
    '#00E5FF',
    ['<', ['get', 'length'], 25],
    '#00E676',
    ['<', ['get', 'length'], 50],
    '#FFFF99',
    ['<', ['get', 'length'], 100],
    '#FF6D00',
    '#CC99FF',
  ],
  'fill-opacity': [
    'case',
    ['<', ['get', 'length'], 10],
    0.3,
    ['<', ['get', 'length'], 25],
    0.4,
    ['<', ['get', 'length'], 50],
    0.5,
    ['<', ['get', 'length'], 100],
    0.7,
    0.85,
  ],
  'fill-outline-color': '#333',
};
