import { EffectMarkupsCreateSchema } from '@fjuel/schemas';
import { z } from 'zod';

import { PriceMarkupCommonSchema } from './PriceMarkupCommonSchema.ts';

export const PriceMarkupUpdateSchema = PriceMarkupCommonSchema.extend({
  id: z.string().uuid().describe('Unique identifier of the price markup'),
  effectMarkups: EffectMarkupsCreateSchema,
});

export type PriceMarkupUpdate = z.infer<typeof PriceMarkupUpdateSchema>;
