import { Box, styled } from '@mui/material';
import { FC, ReactNode } from 'react';

import { appBarHeight } from './consts.ts';

interface MainProps {
  children: ReactNode;
}

const StyledMain = styled(Box)<MainProps>(() => ({
  flexGrow: 1,
  gridColumn: '2 / -1',
  overflowY: 'auto',
  height: `calc(100vh - ${appBarHeight})`,
}));

export const Main: FC<MainProps> = ({ children }) => {
  return <StyledMain>{children}</StyledMain>;
};
