import { Typography } from '@mui/material';

import { WidgetHeaderProps } from './WidgetHeader.types';

export const WidgetHeader = ({ children, ...rest }: WidgetHeaderProps) => {
  return (
    <Typography
      variant="h4"
      component={rest.component ?? 'h1'}
      color={'primary'}
      sx={{ 'text-wrap': 'balance' }}
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  );
};
