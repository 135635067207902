import { fjuelApi } from '@fjuel/configs/api';
import { createQuery } from 'react-query-kit';

import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

type Variables = {
  ipcId: string | null;
  test: string;
};

export const useIpcData = createQuery<unknown, Variables>({
  primaryKey: 'ipc',
  queryFn: async ({ queryKey: [, { ipcId }] }) => await fjuelApi.get(`ipc/${ipcId}/data`).json(),
  use: [disableIfVariablesUndefined(['ipcId'])],
});
