import { EffectMarkupsSchema } from '@fjuel/schemas';
import { z } from 'zod';

import { PriceMarkupCommonSchema } from './PriceMarkupCommonSchema.ts';

export const PriceMarkupSchema = PriceMarkupCommonSchema.extend({
  id: z.string().uuid().describe('Unique identifier of the price markup'),
  effectMarkups: EffectMarkupsSchema,
});

export type PriceMarkup = z.infer<typeof PriceMarkupSchema>;

export const PriceMarkupArraySchema = z.array(PriceMarkupSchema);

export type PriceMarkupArray = z.infer<typeof PriceMarkupArraySchema>;
