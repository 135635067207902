import { FC } from 'react';
import { AppBar as MaterialAppBar, Box, Button, Toolbar, styled } from '@mui/material';
import { List } from '@phosphor-icons/react';

import FjuelLogo from '../assets/icons/fjuel.svg?react';

import { appBarHeight } from './consts.ts';

interface AppBarProps {
  onClick: () => void;
}

const StyledLogoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 1,
  textDecoration: 'none',
  color: 'inherit',
  height: '100%',
}));

export const AppBar: FC<AppBarProps> = ({ onClick }) => {
  return (
    <MaterialAppBar
      position="static"
      style={{ backgroundColor: 'inherit', height: appBarHeight, color: 'inherit', boxShadow: 'none' }}
    >
      <Toolbar style={{ display: 'flex', alignItems: 'center', border: 0, padding: 0 }}>
        <StyledLogoBox>
          <FjuelLogo style={{ width: 'auto', height: '70%' }} />
        </StyledLogoBox>
        <Button onClick={onClick} endIcon={<List weight="duotone" />} color="inherit" sx={{ padding: 3 }} />
      </Toolbar>
    </MaterialAppBar>
  );
};
