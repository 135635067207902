import { Box, Container, styled } from '@mui/material';
import { SideMenu } from '@fjuel/components/SideMenu';
import { Main } from '@fjuel/layout/Main';
import { FC, ReactNode } from 'react';

interface DesktopLayoutProps {
  children: ReactNode;
}

const StyledBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(0,262px) minmax(0, 1fr)',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

export const DesktopLayout: FC<DesktopLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="xl">
      <StyledBox>
        <SideMenu />
        <Main>{children}</Main>
      </StyledBox>
    </Container>
  );
};
